/* wellness module styles start here */
.main-box {
  background: #2e3151;
  width: 100%;
  padding: 2rem 0;
  border-radius: 10px;
}
.w-80 {
  width: 83%;
}
.main-box h5 {
  color: #fff;
  font-size: 18px !important;
}
.main-box p {
  font-size: 14px;
  color: #fff;
}

.progress-name {
  color: #fff;
  width: 60%;
  text-align: left;
  font-size: 16px;
  margin-left: 5px;
}
.progress-name span {
  vertical-align: -webkit-baseline-middle;
}
.progressbar-div {
  width: 35%;
  display: flex;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
}
.slider-on {
  margin-left: 4px;
  vertical-align: sub;
  color: #fff;
  font-weight: normal;
}
.slider-off {
  float: right;
  margin-right: 4px;
  vertical-align: sub;
  margin-top: 5px;
  font-weight: normal;
  color: #fff;
}
span.progress-back {
  background: #fff;
  width: 100%;
  height: 30px;
  border-radius: 100px;
  padding: 3px;
}
.progress-top {
  background: #6264a5;
  position: absolute;
  width: 8%;
  height: 28px;
  margin-top: 1px;
  border-radius: 100px;
  margin-left: 2px;
}
.activity select {
  width: 45%;
  margin: auto;
}
.devices-div {
  background: #ebecef;
  border-radius: 10px;
  padding: 1rem;
}
.devices-div img {
  width: 155px;
}
.devices-div span {
  font-size: 11px;
}
.track-box {
  width: 100%;
  background: #ebecef;
  margin: 0 1rem;
  padding: 0.5rem 0;
  border-radius: 6px;
  margin-bottom: 1rem;
}

.track-box span {
  font-weight: 600;
}
.transform-transilate {
  transform: rotate(-60deg) skewY(30deg) !important;
  margin-top: -7.8rem !important;
}
.clear-left {
  clear: none !important;
  margin-left: 8.8rem !important;
  margin-top: -1.2rem !important;
}
.child {
  margin-top: -1.2rem !important;
  margin-bottom: 0 !important;
  transform: translateX(56%) rotate(-60deg) skewY(30deg) !important;
}
.btn-reading {
  margin: auto;
  background: #06eeb2;
  padding: 0.4rem 1rem;
  border: none;
  /* font-weight: bold; */
  margin-top: 40%;
  border-radius: 5px;
}
.videobtn-mr {
  margin-top: 58%;
}
.tab-content {
  position: inherit;
  top: inherit;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  padding: 20px;
  border: 1px solid #ccc;
  display: block;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  margin-right: 1rem;
  margin-bottom: 3px;
  background: #ececf0;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #fff;
  background-color: #2e3151;
  border-color: #2e3151;
}
.nav-tabs {
  border-bottom: 0;
}
.tab-content {
  position: inherit;
  top: inherit;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  padding: 20px;
  border: 0;
  border-top: 1px solid #ececf0;
  border-bottom: 1px solid #ececf0;
  display: block;
  color: #fff;
}
.ob-new {
  width: 45.2% !important;
  padding-bottom: 53% !important;
}
.ob-new img {
  height: 336px;
  margin-left: -35px;
}
/* .CollabLeave{
position: inherit;
} */
.menu-mb {
  margin: auto;
  padding: 0.3rem 0;
  background: #32355d;
  border-radius: 14px;
}
.status-bubble {
  width: 15px;
  height: 15px;
  background: green;
  display: block;
  margin: auto;
  border-radius: 50%;
}
.status-bubble-50per {
  background-color: orange;
}
.status-bubble-0per {
  background-color: red;
}
.Progress-bar {
  border: 4px solid #fff;
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
  width: 56%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.progress-bar-50per {
  border-left-color: orange;
  border-top-color: orange;
}
.progress-bar-0per {
  border-color: white;
}
.progress-bar-100per {
  border-color: green;
}
th,
td {
  color: #fff;
}
td select {
  width: -webkit-fill-available;
}
.device-img {
  width: 40%;
}
.color-white {
  color: #fff;
}
.conference-bg {
  background: #2e3151;
  border-radius: 10px;
}
.conference-bg .CollabMenu {
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 !important;
}
.roulette-video {
  height: 100%;
  display: flex;
  padding: 20px;
  justify-content: center;
  width: 100% !important;
}
.roulette-video li {
  margin: 2rem 1rem;
}
.menu-main {
  background: #3b4163;
  border-radius: 5px;
  padding: 0.3rem 0;
}
.menu-main .CollabLeave {
  position: inherit !important;
}
.roulette-video .OBhex {
  width: 29.2% !important;
  padding-bottom: 34.17% !important;
}
.opacity {
  opacity: 0.3;
}
.watermark {
  position: fixed;
  bottom: 6%;
  right: 10%;
}
.wellnessmenu-active::before {
  border-color: #30D5C8 !important;
}
.wellnessmenu-active::after {
  border-color: #30D5C8 !important;
}
.wellnessmenu-active {
  border-color: #30D5C8 !important;
}
.information-section-hex .OBhex {
  width: 20.2% !important;
  padding-bottom: 24% !important;
}
.fourth-child {
  top: auto !important;
  margin-left: 0 !important;
}
.OB4 {
  margin-left: 5rem;
  top: -32px !important;
}
.OB5 {
  clear: inherit !important;
  margin-left: -68px !important;
}
.seventh-child {
  clear: inherit;
}
.OB6 {
  clear: left;
  margin-left: -4.2rem;
  top: -33px !important;
}
.OB7 {
  margin-left: 0.2rem;
  top: -31px !important;
}
.OB8 {
  clear: inherit !important;
  margin-left: 5rem !important;
  top: -3.9rem;
}
.SidenavHex {
  border-color: transparent;
}
.SidenavHex:before,
.SidenavHex:after {
  border-color: transparent;
}
.SidenavHex img {
  height: 30px;
  margin-top: 0.5rem;
}
.Update-btn {
  background: transparent;
  border-radius: 50%;
  border: 0;
  color: #30D5C8;
  font-size: 26px;
}
.video-react {
  width: 100% !important;
}
.switch {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  top: 2px;
}

input:checked + .slider {
  background-color: #6264a5;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(33px);
  -ms-transform: translateX(33px);
  transform: translateX(18px);
}
.participent-count {
  background: #30D5C8;
  padding: 5px 5px;
  border-radius: 50%;
  font-size: 14px;
  color: #000;
  margin-left: 5px;
}
.participent-img {
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 50%;
  margin: 0 2px;
}
.enroll-btn {
  background: #30D5C8;
}
.send-icon {
  position: absolute;
  right: 0;
  background: #30D5C8;
  padding: 7px 13px;
  margin-top: -2.7rem;
  border-radius: 5px;
  font-weight: bold;
}
.challenge-list {
  color: #fff;
  text-decoration: underline;
  list-style-type: disclosure-closed;
}
.leaderboard-link:hover {
  color: #30D5C8;
}
.fontsize-18 {
  font-size: 18px;
}
.leaderboard-link:hover {
  color: #30D5C8;
}
.fontsize-14 {
  font-size: 14px;
}
.challenges-textarea {
  background: transparent;
  border: none;
  color: #fff;
  height: 85px !important;
  overflow: auto;
  border-bottom: 1px solid #fff;
}
.modal-body .challenge-form h5 {
  font-size: 14px;
}
.modal-body .challenge-form .challenges-textarea{
  height: 60px !important;
} 
.challengesview-input {
  background: transparent;
  border: none;
  color: #fff;
  border-bottom: 1px solid;
  padding-left: 0;
  border-radius: 0;
}
.images-expand {
  background: #fff;
  padding: 4px 6px;
  border-radius: 50%;
  display: inline-block;
}
.leaderboard-link {
  margin-top: 2rem !important;
  color: white;
  text-decoration: underline;
  cursor: pointer;
}
.challenge-form h5 {
  color: #fff;
  font-size: 16px;
}
.challenges-menu {
  padding-left: 15px;
  margin-top: 1rem;
}
.challenges-menu li {
  color: #fff;
  list-style-type: disclosure-closed;
  margin-bottom: 1rem;
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/* wellness module styles ends here */

@media (max-width: 1000px) {
  .col-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .w-80 {
    width: 100%;
  }
  .main-box .col-5,
  .col-7 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .main-box .col-5 {
    margin-top: 1rem;
  }
}
