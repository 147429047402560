.welcome {
    background: #0C0C0C4D;
    backdrop-filter: blur(35.90999984741211px);
    padding: 30px;
    border-radius: 20px;
    color: #fff;
    height: 100%;
    border: 1px solid gray;
}
.logo {
    img{
    width: 120px;
    margin-bottom: 2rem;
}
}