.visibility {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}

.notice-message-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  top: 1rem;
  right: 10px;
  width: 300px;
  min-height: 70%;
  position: absolute;
  z-index: 3000000;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}
.toast.showToast{
  opacity: 1;
  max-height: 100px;
  width: 100%;
}
.custom-toast {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: .7rem;
  .hidden {
    visibility: hidden;
  }
  .toast-container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    z-index: 99;
    min-height: 34px;
    background: rgba(0, 0, 0, 0.7);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 0 14px;
    .text {
      font-size: 12px;
      color: #fff;
      line-height: 17px;
    }
  }
  .toast-text {
    font-size: 12px;
    width: 210px;
    height: 34px;
    background: #000000;
    opacity: 0.25;
  }
}
.toast {
  flex-basis: 100%;
  max-width: 100%;
  background-color: var(--DarkBlueGrey);
  color: #fff;
  font-size: 12px;
  box-shadow: 0 0.25rem 2rem 5px #0000002e;
}
.toast-header {
  color: #fff;
  background-color: var(--SpaceCadet);
  border-bottom: none;
  font-weight: 300;
  font-size: 13px;
}
.toast-warning {
  background: #ffc107!important;
}
.toast-warning .toast-header {
  background: #ab8000!important;
}
.toast-error {
  background: #dc3545!important
}
.toast-error .toast-header {
  background: #b91c2b!important
}
.toast-footer{
  text-align: center;
  border-top: 1px solid #888fda;
}
.webnotice.notice-message-container{
  width: 100%;
  top: 0;
}
.webnotice .toast.showToast{
  width: 30%;
  top: 1rem;
} 