$myFont: "DM Sans", sans-serif;

body {
    margin: 0;
    font-family: $myFont;
    letter-spacing: 0.5px;
}

@mixin bg {
    background-attachment: fixed !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    max-height: fit-content;
    background-position: center !important;
}

@mixin cmix {
    object-fit: cover;
    border-radius: 50%;
    background: #303252;
    border: 1px solid #ffffff40;
}

@mixin icon {
    font-size: 22px;
    background: rgba(12, 12, 12, 0.3019607843);
    border-radius: 50%;
    width: auto;
    border: 1px solid gray;
    margin-right: 0;
    padding: 3px;
    box-shadow: -1px 0px 4px 0px rgba(255, 255, 255, 0.2509803922) inset;
}

.main {
    @include bg;
    background: url("assets/images/sign-in-bg.png");
    min-height: 100vh;
}

.my-profile {
    .inner-bg {
        @include bg;
        background: url("assets/images/profile-bg.jpeg");
    }
}

.inner-bg {
    @include bg;
    background: url("assets/images/inner-bg.png");
}

.resources {
    .inner-bg {
        @include bg;
        background: url("assets/images/resources-bg.jfif");
    }
}

.chub {
    .inner-bg {
        @include bg;
        background: url("assets/images/hangout.png");
    }
}

.wellnessCenter {
    .inner-bg {
        @include bg;
        background: url("assets/images/wellness-bg.jpeg");
    }
}

.conference {
    .inner-bg {
        @include bg;
        background: url("assets/images/conference-bg.jpeg");
    }
}

.rooms {
    .inner-bg {
        @include bg;
        background: url("assets/images/conference-bg.jpeg");
    }
}

.lobby {
    .inner-bg {
        @include bg;
        background: url("assets/images/lobby-bg.png");
    }
}

.collaboration-hub {
    .inner-bg {
        @include bg;
        background: url("assets/images/mycalender-bg.jpeg");
    }
}

.overlay {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(12, 20, 29, 0.4) 100%);
    // background: linear-gradient(180deg, rgba(47, 49, 81, 0.5) 0%, rgba(12, 20, 29, 0.5) 100%);
    min-height: 100vh;

}

.static-backdrop {
    position: fixed;
    width: 100%;
}

.content {
    width: 80%;
    margin: auto;
    display: flex;
    align-items: center;
    min-height: 100vh;
}

.inner-content {
    width: 70%;
}

.dropdown-menu.show a {
    background: transparent !important;
}

.profile-selection {
    width: 64%;
    background: #0C0C0C4D;
    padding: 20px;
    border-radius: 20px;
    color: #fff;
    margin-left: 1rem;
}

.signin-logo img {
    width: 120px;
    margin-bottom: 1rem;
}

.fw-600 {
    font-weight: 600;
}

.fs-20 {
    font-size: 20px;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-14 {
    font-size: 14px;
}

.know-more {
    background: transparent;
    color: #fff !important;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 0px;
    font-weight: 500;
    font-size: 14px;
    margin-top: 1.5rem;
    border-radius: 8px;
    box-shadow: none !important;
    border: none;
}

.w-100 {
    width: 100%;
}

.label-text {
    width: 100%;
    font-size: 14px;
    margin-bottom: 8px;
    display: block;
}

.form-control {
    background: #21223466;
    border: 1px solid #fff;
    padding: 8px;
    margin-bottom: 1rem;
    border-radius: 8px;
    color: #fff;
    height: inherit;
    box-shadow: -1px 0px 4px 0px rgba(255, 255, 255, 0.2509803922) inset;
}

.PP {
    padding-right: 2.5rem !important;
}

.border-bottom {
    border-color: #ffffff40 !important;
}

.form-control:focus {
    background: #21223466;
    color: #fff;
    box-shadow: none;
    outline: none;
    border-color: #fff;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: transparent !important;
    opacity: 1;
}

.text-white {
    color: #fff
}

.float-right {
    float: right;
}

.login-btn {
    color: #fff;
    margin-top: 3.8rem;
    border: 1px solid #ffffff61;
    padding: 5px 8px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgb(255 255 255 / 0%)), linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, #2F3151, #2f3151);
    border-radius: 8px;
    letter-spacing: 1px;
    font-size: 14px;
    cursor: pointer;
}

.password-icon {
    position: relative;
    float: right;
    margin-top: -3.1rem;
    margin-right: 10px;
    font-size: 16px;
    cursor: pointer;
}

.password-icon svg {
    font-size: 20px;
}

.m-auto {
    margin: auto;
}

.underline {
    text-decoration: underline !important;
}

.arrows img {
    width: 35px !important;
    height: 35px !important;
    margin: 1rem 0;
}

.d-none {
    display: none;
}

.btn-text {
    vertical-align: sub;
    font-size: 16px;
    margin-top: 3px;
    display: inline-block;
}

.btn-img {
    width: 30px;
    float: right;
}

.get-link-bn-mr {
    margin-top: 8rem;
}

.required {
    color: red;
    font-size: 14px;
    top: -.3em;
}

.btn-blue {
    background: #2F3151;
    color: #fff;
    padding: 3px 10px;
    border: 1px solid #ffffff7a;
    border-radius: 10px;
    font-size: 14px;
}

.btn-glass {
    color: #fff;
    padding: 3px 10px;
    border: 1px solid rgba(255, 255, 255, 0.4784313725);
    border-radius: 10px;
    font-size: 14px;
    // border-bottom-color: #6a6a6a;
    background: transparent;
    box-shadow: -1px 0px 4px 0px #FFFFFF40 inset;
    margin-left: 0.5rem;
}
.w-45{
    width: 45% !important;
}
.cf-mr{
    margin-top: -3.5rem !important;
}
.btn-maroon {
    background: #784444;
    color: #fff;
    box-shadow: -1px 0px 4px 0px rgba(255, 255, 255, 0.2509803922) inset;
    width: 48%;
    padding: 7px 5px;
    border: 1px solid #ffffff40;
    border-radius: 8px;

    &:hover {
        background: transparent;
    }

    svg {
        font-size: 20px;
        margin-right: 0.5rem;
        vertical-align: bottom;
    }
}
.w-75 {
    width: 75%;
}
.pr-0 {
    padding-right: 0;
}

textarea::placeholder {
    color: #fff !important;
}

.inner {
    width: 82%;
    margin-top: 2.5rem;
}
td{
    .dropdown-menu.show{
        right: auto !important;
    }
}
.wellness{
    .gradientBox{
        .dropdown-menu.show {
            transform: inherit !important;
        }
    }
}
.dropdown-menu.show {
    display: block;
    // width: 100%;
    right: 0;
    left: auto;
    background: #2f3151;
    border: 1px solid gray;
    padding: 5px;
    border-radius: 10px;
    width: fit-content;
    margin-left: auto;

    a {
        color: #fff;
        border: none;
        padding: 0 8px;
        margin-top: 0.5rem;
    }
}

.mr12 {
    margin-right: 12px;
}

.bb-none {
    border-bottom: none !important;
}

.hand {
    cursor: pointer;
}

.cross svg {
    font-size: 22px;
    background: #0C0C0C4D;
    border-radius: 50%;
    margin-top: 1rem;
    width: auto;
    padding: 2px;
    box-shadow: -1px 0px 4px 0px #FFFFFF40 inset;
    float: right;
    border: 1px solid gray;
    cursor: pointer;
    margin-right: 0.5rem;
}

.gradientBox {
    .gradientBox {
        margin: 0;
        height: auto;
        border-radius: 0;
        border: none;
        background: transparent;
        box-shadow: none;
    }
}

.star svg {
    color: #FFBB00;
    font-size: 22px;
    margin-right: .5rem;
    cursor: pointer;
}

.blueBox {
    background: #0C0C0C4D;
    padding: 15px 10px;
    box-shadow: -1px 0px 4px 0px #FFFFFF40 inset;
    border-radius: 10px;
}

.contactBox {
    background: #0C0C0C4D;
    box-shadow: -1px 0px 4px 0px #FFFFFF40 inset;
    border-radius: 13px;
    border: 1px solid gray;
    margin-bottom: 5px;
}

.Revent {
    svg {
        background: rgba(0, 0, 0, 0.8);
        border-radius: 50%;
        padding: 4px;
        font-size: 28px;
        border: 1px solid #ffffff40;
    }
}

.commingosoon {
    margin-left: 3rem;
}

/* profile page body content styles starts here */
.profile .cfooter {
    border: none;
}

.border-top {
    border-top: 1px solid #ffffff40 !important;
}

.check svg {
    color: #fff;
}

.profile-bg .overlay {
    background: rgb(12 12 12 / 58%);
}

.body-content {
    width: 85%;
    margin: auto 0px auto 3rem;
    margin-top: 1rem;
    display: flex;
}

.user-img img {
    width: 55px;
    margin-top: -3rem;
    border-radius: 50%;
    height: 55px;
    background: #2f3151;
    object-fit: cover;
    border: 1px solid #ffffff40;
}

.sidenav {
    width: 18%;
    margin-top: 2.5rem;
    padding-bottom: 1rem;
}

.user-info-section {
    background: #0C0C0C4D;
    backdrop-filter: blur(35.90999984741211px);
    padding: 0 10px 10px;
    border-radius: 20px;
    border: 1px solid gray;
}

.user-info-text {
    color: #fff;
    font-size: 14px;
}

.user-info-text-2 {
    color: #fff;
    font-size: 14px;
    word-break: break-word;
    border-color: #ffffff40 !important;
    /* margin-top: 1rem; */
}

.br-20 {
    border-radius: 20px;
}

.Pside-img {
    @include cmix;
    width: 35px;
    height: 35px;
}

.menu-section {
    margin-top: 0.8rem;

    .gradientBox {
        margin-left: 0;
    }

    .body {
        padding: 0 !important;
    }

    svg {
        font-size: 20px;
    }
}

.my-events {
    .gradientBox {
        &:nth-child(2) {
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0px !important;
        }
    }

    .BB0 {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .dateIcon {
        svg {
            border: none;
            border-radius: 0;
            position: static;
            box-shadow: none;
            font-size: 22px;
        }
    }

    .gradientBox {
        background: rgba(12, 12, 12, 0.3019607843);
        border-radius: 20px;
        box-shadow: -1px 0px 4px 0px rgba(255, 255, 255, 0.2509803922) inset;
        backdrop-filter: blur(35.9099998474px);

        .Grheader {
            background: rgba(12, 12, 12, 0.3019607843);
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            box-shadow: -1px 0px 4px 0px rgba(255, 255, 255, 0.2509803922) inset;
        }

        &:first-child {
            margin-top: 0 !important;
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
        }
    }
}

.gradientBox {
    background: #0C0C0C4D;
    border-radius: 20px;
    border: 1px solid gray;
    margin-left: 1rem;
    box-shadow: -1px 0px 4px 0px #FFFFFF40 inset;
    backdrop-filter: blur(35.90999984741211px);
    height: 100%;
    position: relative;

    p {
        color: #fff;
    }

    .body {
        padding: 10px;
        color: #fff;
        transition: max-height 0.3s ease, padding 0.3s ease;
    }

    ul {
        padding-left: 0;
        margin-bottom: 0;
    }
}

.Grheader {
    padding: 10px;
    border-bottom: 1px solid gray;

    .react-datepicker-popper {
        transform: translate(-88px, 51px) !important;
    }

    .react-datepicker__tab-loop svg {
        background: transparent;
        border: none;
        border-radius: 0;
        box-shadow: none;
    }

    svg.react-datepicker__calendar-icon {
        background: transparent;
        border: none;
        border-radius: 0;
        position: static !important;
        box-shadow: none;
        float: inherit !important;
    }
}

.DatepickerIcon .react-datepicker-popper {
    z-index: 1;
    line-height: 0;
    transform: translate(-90px, 51px) !important;
}

.b-hide {
    height: auto;

    .Grheader {
        border-bottom: 0;
    }

    .body {
        display: none;
    }

    .plus {
        display: block !important;
    }

    .minus {
        display: none;
    }
}

.Grheader .plus {
    display: none;
}

.Grheader svg {
    font-size: 22px;
    background: rgba(12, 12, 12, 0.3019607843);
    border-radius: 50%;
    width: auto;
    border: 1px solid gray;
    margin-right: 0;
    float: right;
    padding: 1px;
    box-shadow: -1px 0px 4px 0px rgba(255, 255, 255, 0.2509803922) inset;
}

.pt-0 {
    padding-top: 0 !important;
}

.sidenav li {
    list-style: none;
    color: #fff;
    margin: 0 0 1rem 0;
    cursor: pointer;
}

.sidenav li img {
    width: 20px;
    margin-right: 10px;
    vertical-align: middle;

}

.sidenav li svg {
    width: 20px;
    margin-right: 10px;
    vertical-align: middle;
}

.menu-text {
    font-size: 14px;
}

.checkin-menu-btn {
    background: #1a8361;
    padding: 10px;
    border-radius: 20px;
    margin-top: 0.8rem;
    border: 1px solid gray;
}

.profile-info {
    width: 60%;
    margin-top: 2.5rem;
}

.p10 {
    padding: 10px;
}

.profile-box-img {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    margin-top: 1rem;
    object-fit: cover;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, #2F3151, #2F3151);
    border: 1px solid #ffffff6b;
    box-shadow: -1px 0px 4px 0px #FFFFFF40 inset;
}

.content-profile-div-mr {
    margin-top: 2.5rem;
}

.details-box {
    width: 20%;
    margin-left: 1rem;
    background: #2F3151;
    /* padding: 15px; */
    border-radius: 20px;
    height: 100%;
    border: 1px solid gray;
}

/* .details-box-inner{
    padding: 15px;
    border-bottom: 1px solid #fff;
} */
.profile-edit-img {
    position: absolute;
    margin-top: 2rem;
    width: 32px;
    margin-left: -2rem;
}

.details-box {
    .img-box {
        .check {
            position: absolute;
            margin-left: -2rem;
            margin-top: 1rem;
        }

        .check svg {
            color: #fff;
            background: #1d1f3c;
            padding: 4px;
            font-size: 25px;
            border-radius: 50%;
            border: 1px solid #ffffff40;
            margin-right: 3px;
        }
    }
}

.edit-text-icon {
    width: 23px;
}

.fontsize-12 {
    font-size: 12px;
}

.p-15 {
    padding: 15px;
}

.right-sidemenu {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 40000;
    color: #fff;
    background: #1C1D36;
    width: 27%;
    min-height: 100vh;
    max-height: 100%;
    overflow: auto;
    transition: right 0.3s ease;

    .st-sidebar {
        overflow: auto;
        height: 92vh;

        tr {
            border-bottom: 1px solid #fff;
        }

        td,
        th {
            padding: 8px 5px;
            font-size: 14px;
        }

        tr:last-child {
            border: none !important;
        }

        .form-control {
            border: 1px solid #ffffff40;
            box-shadow: -1px 0px 4px 0px rgba(255, 255, 255, 0.2509803922) inset;
        }

        .video-bg {
            width: 15%;
        }

        span.blur-icon {
            padding: 2px 10px;
            display: inline-block;
            font-size: 25px;
            margin: 2px;
            cursor: pointer;
            color: #fff;
            background: rgba(12, 12, 12, 0.3019607843);
            border-radius: 10px;
            border: 1px solid gray;
            box-shadow: -1px 0px 4px 0px rgba(255, 255, 255, 0.2509803922) inset;
            backdrop-filter: blur(35.9099998474px);
        }
    }

    .add {
        position: absolute;
        right: 1.5rem;
        top: 2.4rem;
        font-size: 14px;
        cursor: pointer;
    }

    .close-img {
        margin-top: 0;
    }

    * {
        transition: right 0.3s ease;
    }
}

.close-img img {
    width: 22px;
}

.close-img {
    float: right;
    margin-top: -0.6rem;
    cursor: pointer;
}

select {
    appearance: auto !important;
}

.file-img {
    width: 22px;
    margin-right: 0.5rem;
}

.checkin-btn {
    position: fixed;
    bottom: 0px;
    right: 0px;
    padding: 10px 20px;
    background-color: #2F3151;
    color: white;
    border: none;
    width: 27% !important;
    text-align: center;
    border: 1px solid transparent;
}

.checkin-btn:hover {
    background: #1c1d36;
    border: 1px solid #6a6a6a;
    border-radius: 2px;
}

.later-btn:disabled {
    opacity: 0.5 !important;
}

.later-btn {
    padding: 10px 20px;
    background-color: #2F3151;
    color: white;
    border-color: transparent;
    width: 43% !important;
    margin: auto;
    margin-left: 1rem;
    border-radius: 4px;
    margin-top: 1rem;
}

.later-btn:hover {
    background: transparent;
    border: 1px solid #6a6a6a;
}

.emojy-select {
    background: #2F3151;
    padding: 3px;
    border-radius: 50%;
    width: 45px !important;
    border: 1px solid #fff;
}

@mixin bubble {
    margin-right: 5px;
    vertical-align: middle;
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 50%;
}

.bubblegreen {
    @include bubble;
    background: #04f1b2;
}

.bubblepurple {
    @include bubble;
    background: #6468a8;
}

.bubbleblue {
    @include bubble;
    background: #38b8f3;
}

.right-sidemenu::-webkit-scrollbar {
    width: 2px;
}

.close-img svg {
    width: 22px;
    height: 22px;
    margin-right: 0;
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 45%;
}

.st-sidebar .form-control svg {
    font-size: 22px;
    width: auto;
    margin-right: 7px;
    padding: 2px;
    border-radius: 50%;
    background: #0c0c0c4d;
    border: 1px solid;
    box-shadow: 2px 2px 4px -1px #ffffff6b inset;
}

.cfooter {
    padding: 5px;
    border-top: 1px solid gray;
}

.avatarDefault img {
    margin: 0;
    width: 43px;
    height: 43px;
}

/* profile page body content ends starts here */


// checkbox styles start here
.Eplus{
    font-size: 20px;
    width: auto;
    margin-right: 7px;
    padding: 0px;
    border-radius: 50%;
    background: rgba(12, 12, 12, 0.3019607843);
    border: 1px solid gray;
    box-shadow: 2px 2px 4px -1px rgba(255, 255, 255, 0.4196078431) inset;
}
.st-sidebar {
    .allday-btn {
        box-shadow: -1px 0px 4px 0px #FFFFFF40 inset;
        background: transparent;
        border: 1px solid #ffffff40;
        color: #ffff;
        font-size: 12px;
        padding: 8px 12px;
        margin-top: .5rem;
        border-radius: 10px;

        span {
            margin-left: .3rem;
        }
    }

    input[type="checkbox"] {
        width: 16px;
        height: 16px;
        vertical-align: middle;
    }

    input[type="checkbox"] {
        height: 18px;
        width: 18px;
        margin: 0;
        padding: 0;
        opacity: 1;
        appearance: none;
        border: 1px solid #ffffff40;
        border-radius: 3px;
        background: transparent;
        position: relative;
        margin-right: 5px;
        box-shadow: -1px 0px 4px 0px rgba(255, 255, 255, 0.2509803922) inset;
    }

    input[type="checkbox"]:checked {
        border: 1px solid #fff;
        background: transparent;
    }

    input[type="checkbox"]:checked:before,
    input[type="checkbox"]:checked:after {
        content: "";
        position: absolute;
        height: 2px;
        background: #fff;
    }

    input[type="checkbox"]:checked:before {
        width: 6px;
        top: 10px;
        left: 2px;
        transform: rotate(44deg);
    }

    input[type="checkbox"]:checked:after {
        width: 13px;
        top: 7px;
        left: 4px;
        transform: rotate(309deg);
    }

    input[type="checkbox"]:focus {
        outline: none;
    }
}

.main {
    .overlay {
        input[type="checkbox"] {
            width: 16px;
            height: 16px;
            vertical-align: middle;
        }

        input[type="checkbox"] {
            height: 18px;
            width: 18px;
            margin: 0;
            padding: 0;
            opacity: 1;
            appearance: none;
            border: 1px solid #ffffff;
            border-radius: 3px;
            background: transparent;
            position: relative;
            margin-right: 5px;
        }

        input[type="checkbox"]:checked {
            border: 1px solid #fff;
            background: transparent;
        }

        input[type="checkbox"]:checked:before,
        input[type="checkbox"]:checked:after {
            content: "";
            position: absolute;
            height: 2px;
            background: #fff;
        }

        input[type="checkbox"]:checked:before {
            width: 6px;
            top: 10px;
            left: 2px;
            transform: rotate(44deg);
        }

        input[type="checkbox"]:checked:after {
            width: 13px;
            top: 7px;
            left: 4px;
            transform: rotate(309deg);
        }

        input[type="checkbox"]:focus {
            outline: none;
        }
    }
}


// contacts css start here

// reources page styles start hear
.col-3 .gradientBox {
    height: auto;
    margin-bottom: 1rem;
}

.resourcesBody {
    padding: 10px;
    color: #fff;

    p {
        font-size: 14px;
    }
}

.feedback-form .checkin-btn {
    background: #2F3151;
    border: none;
    color: #fff;
    padding: 8px;
    position: relative;
    margin-top: 65%;
    bottom: 0;
    height: 100%;
}

.h-200px {
    height: 200px !important;
}

.h-150px {
    height: 150px !important;
}

.rightArrow svg {
    font-size: 24px;
    background: rgba(12, 12, 12, 0.3019607843);
    border-radius: 50%;
    width: auto;
    border: 1px solid gray;
    margin-right: 0;
    color: #fff;
    padding: 4px;
    box-shadow: -1px 0px 4px 0px rgba(255, 255, 255, 0.2509803922) inset;
}

// reources page styles ends hear
.resourceimg {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 2px;
    object-fit: cover;
    height: 160px;
}

.cbody {
    .select {
        background: #2F3151;
    }
}

.cmain {
    border-bottom: 1px solid gray;
    padding: 10px;
}

.cmain:last-child {
    border-bottom: none !important;
}

.svg-box svg {
    margin-right: 5px;
    font-size: 16px;
    cursor: pointer;
}

.cimg {
    width: 38px;
    height: 38px;
    @include cmix;
}

img.cimg-expand {
    width: 82px;
    height: 82px;
    @include cmix;
}

.expandStar {
    svg {
        background: #000000CC;
        padding: 6px;
        font-size: 26px;
        position: absolute;
        right: 10px;
        top: 6px;
        border-radius: 50%;
    }
}

span.copy svg {
    font-size: 20px;
}

span.CInfoI svg {
    padding: 6px;
    font-size: 30px;
    background: #000000CC;
    margin: .5rem;
    border-radius: 50%;
}

.bg-green svg {
    background: var(--Colors-Green, #34C759) !important;
}

.contactOverflow {
    max-height: 120px;
    overflow: auto;
}
.Croom{
    font-size: 14px;
    color: #fff;
    box-shadow: -1px 0px 4px 0px rgba(255, 255, 255, 0.2509803922) inset;
    width: 48%;
    padding: 7px 5px;
    border: 1px solid rgba(255, 255, 255, 0.2509803922);
    border-radius: 8px;
}
.mydesk-collaborate{
    .cbody {
        height: 55vh;
        overflow: auto;
    }
}
.Dplus svg {
    font-size: 19px;
    background: rgba(12, 12, 12, 0.3019607843);
    border-radius: 50%;
    width: auto;
    padding: 2px;
    box-shadow: -1px 0px 4px 0px rgba(255, 255, 255, 0.2509803922) inset;
    border: 1px solid gray;
    cursor: pointer;
}
.r-8 {
    border-radius: 8px;
}

.mydesk {
    .col-3 {
        .gradientBox {
            height: auto;
        }
    }
}
.image-box {
    background: rgba(12, 12, 12, 0.3019607843);
    border-radius: 20px;
    border: 1px solid gray;
    box-shadow: -1px 0px 4px 0px rgba(255, 255, 255, 0.2509803922) inset;
    backdrop-filter: blur(35.9099998474px);
    position: relative;
    width: 46%;
    padding: 20px 10px;
    font-size: 55px;
    text-align: center;
    margin: 1rem 0;
    cursor: pointer;
}

.st-sidebar .tab-pane {
    height: inherit;
}
.h-120px{
    height: 120px !important;
}
.dp.col-12 .body {
    padding: 0;
}

.dp {
    display: flex;

    .gradientBox {
        margin-left: 5px !important;
        width: 100%;
    }

    .cbody {
        max-height: 69vh;
        overflow: auto;
    }
}

span.backArrow svg {
    background: #0C0C0C4D;
    color: #fff;
    backdrop-filter: blur(35.90999984741211px);
    border: 1px solid gray;
    border-radius: 50%;
    box-shadow: -1px 0px 4px 0px #FFFFFF40 inset;
    padding: 5px;
    font-size: 30px;
    cursor: pointer;
}
.Addmember{
    .dropdown button {
        text-align: left;
        background: transparent !important;
    }
    .dropdown-toggle::after{
        margin-top: 0.5rem;
        float: right;
    }
    .dropdown-menu.show {
        width: 100%;
        color: #fff;
    }
}
.callimg{
    width: 150px;
    height: 150px;
}
.Cp-padding{
    padding: 0 4rem;
}
.Call-bg-red {
    background: #FF3B30 !important;
}
.Call-bg-green {
    background: #34C759 !important;
}
// Chat styles start here
.w-25p {
    width: 25%;
}

.chatting {
    background: #0C0C0C4D;
    height: 85vh;
    margin: 2rem 15px 0px 15px;
    padding: 6px 0;
    border-radius: 15px;
    border: 1px solid #ffffff40;
    box-shadow: -1px 0px 4px 0px #ffffff40 inset;

    .flex-column {
        background: black !important;
    }
}

.wifi {
    cursor: default !important;
}

.wifi:hover {
    background: rgba(12, 12, 12, 0.3019607843) !important;
}

.chat {
    .st-sidebar {
        height: 100vh;
    }
}

.ChatHeader {
    border-bottom: 1px solid #ffffff40;
    padding-bottom: 5px;
}

.ChatHeader img {
    width: 40px;
}

em-emoji-picker {
    position: fixed;
    right: 20px;
}
.BR-10{
    border-radius: 10px !important;
}
.chatBody {
    // height: 89%;
    padding: 8px;
    position: relative;
    overflow: auto;

    .incoming {
        text-align: right;
        width: 100%;
    }

    .msg {
        margin-left: auto;
        background: #1D1F3C;
        padding: 5px 8px;
        border: 1px solid gray;
        border-radius: 8px;
        border-bottom-right-radius: 0;
        margin-bottom: 1rem;

        p {
            margin-bottom: 0;
        }
    }

    .chat-container {
        max-width: 100%;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        flex-flow: column nowrap;
    }

    .msgHeader {
        font-size: 10px;
        margin-bottom: 0;
        color: rgb(255 255 255 / 62%);
    }

    .messageParent {
        display: contents;
    }

    .messages {
        height: 71vh;
        // padding: 10px;
        /* Allow vertical scrolling */
        overflow-y: auto;
        display: flex;
        flex-flow: column-reverse;

        /* Start from the bottom */
        ul {
            padding: 0;
        }
    }

    .messages> :first-child {
        margin-top: auto !important;
        /* use !important to prevent breakage from child margin settings */
    }

    .message {
        font-size: 14px;
        margin: 5px 0;
        padding: 6px 10px;
        border-radius: 20px;
        max-width: 75%;
        word-wrap: break-word;
        border: 1px solid #ffffff40;

        p {
            margin-bottom: 5px;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    .message.sent {
        background-color: #1D1F3C;
        color: white;
        align-self: flex-end;
        position: relative;
        margin-right: 6px;
    }

    .message.sent::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        border-left: 10px solid #ffffff40;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        transform: rotate(-417deg);
        z-index: -1;
    }

    .message.received {
        background-color: #000000;
        color: #fff;
        align-self: flex-start;
        position: relative;
        margin-left: 5px;
    }

    .message.received::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0px;
        border-right: 10px solid #ffffff40;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        transform: rotate(-298deg);
        z-index: -1;
    }

    .message img {
        width: 100%;
    }
}

.chatArea {
    background: transparent;
    border: none;
    width: 100%;
    outline: none !important;
    color: #fff;
    resize: none;
    padding: 13px 67px 0 38px;
}

.chat-close svg {
    position: fixed;
    color: #000000;
    z-index: 3;
    right: 1%;
    font-size: 25px;
    background: #fff;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
    margin-top: 0 !important;
}

.chatFooter {
    border-top: 1px solid #ffffff40 !important;
    position: relative;

    span {
        svg {
            position: absolute;
            margin-top: 1rem !important;
        }
    }

    .emoji {
        position: absolute;
        right: 3rem;
        background: rgba(12, 12, 12, 0.3019607843);
        color: #fff;
        padding: 0px 1px;
        font-size: 16px;
        border-radius: 50%;
        border: 1px solid rgba(255, 255, 255, 0.2509803922);
        box-shadow: -1px 0px 4px 0px rgba(255, 255, 255, 0.2509803922) inset;
        cursor: pointer;
        margin-top: 1rem !important;
    }

    .emoji-select {
        border: 1px solid;
        border-radius: 50%;
    }

    .chat-files {
        position: absolute;
        background: #2f3151;
        margin-left: 2px;
        border-radius: 10px;
        bottom: 2.3rem;

        svg {
            position: relative;
            display: block;
            font-size: 25px;
            margin-right: 0;
            margin-top: 0;
            display: block;
            padding: 5px;
            margin: 5px !important;
        }
    }

    .attach {
        svg {
            background: #0C0C0C4D;
            font-size: 22px;
            border: 1px solid #ffffff40;
            border-radius: 50%;
            margin-left: 0.5rem;
            margin-top: 1rem;
        }
    }

    .send svg {
        margin-top: .7rem;
        right: 1rem;
        font-size: 26px;
        background: rgba(12, 12, 12, 0.3019607843);
        color: #fff;
        padding: 4px;
        border-radius: 50%;
        border: 1px solid rgba(255, 255, 255, 0.2509803922);
        box-shadow: -1px 0px 4px 0px rgba(255, 255, 255, 0.2509803922) inset;
    }
}

// Chat styles ends here

// tabs styles start here
.NavTab {

    .nav-tabs {
        background: rgba(12, 12, 12, 0.3019607843);
        border-radius: 20px;
        border: 1px solid gray;
        box-shadow: -1px 0px 4px 0px rgba(255, 255, 255, 0.2509803922) inset;
        width: 100%;
        padding: 5px;
        padding-bottom: 0 !important;
        height: fit-content;
        backdrop-filter: blur(35.9099998474px);
    }

    .tab-content {
        display: block;
        position: relative;
        width: 100%;
        top: 0.5rem;
        background: transparent;
        border: none;
        height: auto;
        max-height: inherit;
        min-height: 70vh;
    }

    .nav-link {
        background: transparent !important;
        border: none;
        color: #C2C2C2;
        margin-right: 0;
        margin-left: 1rem;
        padding-left: 0;
        padding-right: 0;
    }

    .nav-link.active {
        border-bottom: 2px solid #fff !important;
        font-weight: bold;
        color: #fff !important;
        padding-top: 0;
        margin-top: 0.4rem;
    }
}

.right-sidemenu .commingosoon {
    margin: 0;
    margin-top: 2rem;
}

.right-sidemenu .commingosoon .col-7 {
    max-width: 95%;
}

// tabs styles ends here

// validatios css start here
.validation {
    position: absolute;
    right: 1%;
    z-index: 3000000;
    width: 25%;
    background: linear-gradient(180deg, rgb(0 0 0 / 36%) 0%, #00000069 100%);
    color: #fff;
    top: 11%;
    font-size: 12px;
    padding: 10px 2px;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.2509803922);
    box-shadow: -1px 0px 4px 0px #FFFFFF40 inset;

    .cross {
        svg {
            margin-top: 0;
        }
    }

    .success {
        svg {
            background: #0C0C0C4D;
            padding: 5px;
            font-size: 40px;
            border-radius: 50%;
            border: 1px solid #ffffff40;
            box-shadow: 2px 1px 4px 0px #ffffff40 inset;
            color: #34C759;
        }
    }

}

.DExpand {
    background: #0C0C0C4D;
    backdrop-filter: blur(35.90999984741211px);
    box-shadow: -1px 0px 4px 0px #FFFFFF40 inset;
    border: 1px solid gray;
    border-radius: 10px;
    padding: 6px;
}

.Dimg {
    width: 38px;
    height: 38px;
    @include cmix;
}

.wordBr {
    word-break: break-all;
}

// Meeting room styles start here
.Join-btn {
    background: #2F3151;
    color: #fff !important;
    border: 1px solid #ffffff40;
    padding: 3px 18px;
    border-radius: 10px;

    &:hover {
        background: transparent;
    }
}

.conference {
    .sidenav {
        display: none;
    }

    .body-content {
        display: block;
    }
}

.btn-dgreen {
    background: #48FF454D;
}

.Geffets {
    border-radius: 50% !important;
    background: #303252 !important;
    border: 1px solid #ffffff40 !important;
    padding: 2px 7px !important;
    font-size: 20px !important;
    position: fixed;
    bottom: 10px;
    margin-left: -2rem !important;
}

.w-40 {
    width: 40% !important;
}

.w-65 {
    width: 65% !important;
}

.tabs-effects {
    .blur-icon {
        background: rgba(12, 12, 12, 0.3019607843) !important;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, #2F3151, #2F3151);
        border: 1px solid #ffffff6b;
        box-shadow: -1px 0px 4px 0px #FFFFFF40 inset;
    }

    .video-bg {
        width: 9.8%;
        height: 35px;
    }

    .fixed-color {
        width: 19px;
        height: 19px;
    }

    .bgActive {
        zoom: inherit;
    }

    .device-name {
        color: white;
        margin-top: 1rem !important;
    }
}

#dom-textUser {
    height: 100%;

    video {
        position: relative !important;
    }

    div {
        border-top-left-radius: 20px;
    }
}

.Guestimg {
    height: 62vh;
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
}

.tabbg-hide {
    .nav-tabs {
        background: transparent;
        box-shadow: none !important;
        border-top: 0;
        border-left: 0;
        border-radius: 0;
        border-right: 0;
    }

    .tab-content {
        min-height: auto;
    }

    .tab-pane {
        height: auto;
    }
}

.pin {
    overflow: hidden !important;

    .personMain {
        flex: 100% !important;
        height: 75vh;
    }
}

.files-modal {
    max-height: 60vh;
    overflow: auto;
}

// .personImg div {
//     position: absolute;
//     top: 0;
//     width: 100%;
//     left: 0;
// }
.Cexpand {
    padding-top: 0.5rem;
     .body-content {
        width: 99%;
        margin-top: 0 !IMPORTANT;
    }
    .ConferenceMain {
        height: 87vh;
        margin-top: 0 !important;
    }
}
.tab-content{
    @media screen and (-webkit-min-device-pixel-ratio:0) {
        input[type='range'] {
            overflow: hidden;
            width: 100%;
            -webkit-appearance: none;
            background: #0C0C0C4D;
            backdrop-filter: blur(35.90999984741211px);
            box-shadow: -1px 0px 4px 0px #FFFFFF40 inset;
            border-radius: 5px;
            border: 1px solid #808485;
        }
    
        input[type='range']::-webkit-slider-runnable-track {
            height: 15px;
            -webkit-appearance: none;
            color: #13bba4;
            margin-top: -1px;
        }
    
        input[type='range']::-webkit-slider-thumb {
            width: 6px;
            -webkit-appearance: none;
            height: 25px;
            //   cursor: ew-resize;
            background: #fff;
            box-shadow: -250px 0 0 250px #2F3151;
        }
    
    }
}
.view-summary {
    background: hsla(0, 0%, 5%, .302);
    box-shadow: inset -1px 0 4px 0 hsla(0, 0%, 100%, .251);
    border: 1px solid gray;
    border-radius: 10px;
    font-size: 14px;
    backdrop-filter: blur(35.9099998474px);
    padding: 5px !important;
    color: #fff;
}
.view-summary:hover{
    background: #2f3151 !important;
}
.ConferenceMain {
    width: 100%;
    height: 75vh;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
    .Guestimg {
        img {
            @include cmix;
            width: 220px;
            height: 220px;
        }
    }

    .screen-share {
        width: 76%;
        height: 70vh;
    }

    .persons {
        width: 24%;
        display: flex;
        flex-wrap: wrap;
    }

    .personMain {
        text-align: center;
        flex: 25%;
        margin: 0 auto !important;
        border: 1px solid rgba(255, 255, 255, 0.2509803922);
        border-bottom: 0 !important;
        border-left: 0;
        // height: 100%;

        .person-center {
            height: 100%;
            width: 100%;
            margin: auto;
        }

        .personImg {
            width: 100%;
            height: 100%;
            margin-top: -2.2rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    // .personMain:nth-child(4) {
    //     border-top-right-radius: 20px !important; /* Rounded top-right corner */
    // }
    // .personMain:first-child {
    //     border-top-left-radius: 20px !important;
    //  }
    //  .personMain:last-child {
    //     border-bottom-right-radius: 20px !important;
    //  }
    /* Remove left border for the first child */
    // .personMain:first-child {
    //     border-left: none;
    // }

    /* Remove bottom border for the third, fourth, and last child */
    // .personMain:nth-child(4),
    // .personMain:nth-child(5),
    // .personMain:last-child {
    //     border-left: none;
    // }
    // .personMain:nth-child(1),
    // .personMain:nth-child(2),
    // .personMain:nth-child(3),
    // .personMain:nth-child(4)
    // {
    //     border-top: none;
    //     border-bottom: 2px solid #ffffff40;
    // }
    // .personMain:nth-child(5){
    //     border-left: none;
    // }
    .personImg {
        img {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            object-fit: cover;
            background: #2f3151;
            border: 1px solid #ffffff40;
            // margin-top: 2.5rem;
        }

        // video{
        //     position: inherit !important;
        // }
    }

    .Name {
        color: #fff;
        margin-top: -1.8rem;
        z-index: 2;
        position: relative;
        bottom: 0;
    }
    /*Chrome*/
}
.record-btn {
    background: rgba(12, 12, 12, 0.3019607843);
    color: #fff;
    padding: 10px;
    vertical-align: middle;
    margin-right: 1rem;
    border-radius: 10px;
    margin-top: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.2509803922);
    box-shadow: -1px 0px 4px 0px rgba(255, 255, 255, 0.2509803922) inset;
    backdrop-filter: blur(35.9099998474px);
}
.record-bubble {
    font-size: 14px;
    width: 12px;
    display: inline-block;
    height: 12px;
    background: #fff;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 2px;
}
.recorded-bubble{
    width: 10px;
    display: inline-block;
    font-size: 14px;
    height: 10px;
    background: red;
    vertical-align: middle;
    margin-right: 2px; 
}
.HB {
    display: none;

    svg {
        background: rgb(22 23 40);
        color: #fff;
        padding: 2px;
        border-radius: 50%;
        border: 1px solid rgba(255, 255, 255, 0.2509803922);
        box-shadow: -1px 0px 4px 0px rgba(255, 255, 255, 0.2509803922) inset;
        font-size: 18px;
    }
}

.Mh {
    .dropdown-menu {
        min-width: fit-content !important;
        border: 1px solid rgba(255, 255, 255, 0.2509803922);
        box-shadow: -1px 0px 4px 0px rgba(255, 255, 255, 0.2509803922) inset;
        cursor: pointer;
        backdrop-filter: blur(35.9099998474px);
        background: rgb(12 12 12 / 70%);

        a {
            margin-top: 0 !important;
        }
    }

    .gradientBox {
        position: absolute;
        height: auto;
        padding: 4px 5px;
        border-radius: 5px;
        top: 39px;
        left: 5px;
        font-size: 8px;
        z-index: 2;
        margin-left: 0;
    }

    // .right-0{
    //     right: 0;
    //     left: inherit;
    // }
}

.Mh:hover {
    .HB {
        display: inline-block;
    }
}

.copy {
    position: absolute;
    top: 5px;
    right: 7px;
}

.Cp {
    padding-right: 2rem;
}

.middle {
    vertical-align: middle;
}

.h-100vh {
    height: 100vh !important;
}

.h-60vh {
    height: 63vh;
    overflow: auto;
}

.Cbtn {
    .notification {
        width: 10px;
        height: 10px;
        display: inline-block;
        position: absolute;
        background: #d39e00;
        margin-top: 1rem;
        z-index: 2;
        margin-left: 1.8rem;
        border-radius: 50%;
    }

    svg {
        background: #0C0C0C4D;
        color: #fff;
        padding: 10px;
        font-size: 45px;
        border-radius: 50%;
        margin-right: 1rem;
        margin-top: 1rem;
        border: 1px solid #ffffff40;
        box-shadow: -1px 0px 4px 0px #FFFFFF40 inset;
        cursor: pointer;
        backdrop-filter: blur(35.90999984741211px);

        &:hover {
            background: #2f3151;
        }
    }

    .select {
        background: #34C759;
    }
}

.header-btns {
    p {
        display: flex;
        justify-content: end;
    }

    svg {
        color: #fff;
        font-size: 20px;
        z-index: 4;
        position: relative;

        &:hover {
            background: #2f3151;
        }
    }

    .voice {
        background: #0C0C0C4D;
        color: #fff;
        padding: 5px;
        font-size: 25px;
        border-radius: 50%;
        border: 1px solid rgba(255, 255, 255, 0.2509803922);
        box-shadow: -1px 0px 4px 0px rgba(255, 255, 255, 0.2509803922) inset;
        cursor: pointer;
    }
}

.btn-exit {
    margin-top: 1rem;
    background: var(--Colors-Red, #FF3B30);
    border: none;
    padding: 5px 10px;
    border-radius: 10px;

    svg {
        background: transparent;
        padding: 0;
        font-size: 30px;
        margin-right: 0;
        vertical-align: middle;
        margin-top: 0;
        border: none;
    }

    .exit-text {
        font-size: 18px;
        vertical-align: middle;
        color: #fff;
        margin-right: 5px;
    }
}

.leave-popover {
    position: absolute;
    right: 0;
    top: -8rem;

    button {
        padding: 6px 23px;
        border: none;
        border-radius: 20px;
        color: #fff;
    }
}

.btn-green {
    background: #34C759;
}

.btn-red {
    background: var(--Colors-Red, #FF3B30);
}

// voice spinner styles start here
.Sbox {
    background: rgba(12, 12, 12, 0.3019607843);
    color: #fff;
    padding: 5px;
    font-size: 25px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.2509803922);
    box-shadow: -1px 0px 4px 0px rgba(255, 255, 255, 0.2509803922) inset;
    cursor: pointer;
    position: relative;
    z-index: 2;
    width: 25px;
    height: 25px;
    display: inline-block;
    // right: -2.5%;
    top: 2px;
}

.loader {
    width: 3px;
    height: 3px;
    border-radius: 4px;
    display: block;
    margin: 3px auto;
    /* position: absolute; */
    background: currentColor;
    color: #FFF;
    right: 2%;
    box-sizing: border-box;
    animation: animloader 0.3s 0.3s linear infinite alternate;
    z-index: 2;

}

.loader::after,
.loader::before {
    content: "";
    width: 3px;
    height: 3px;
    border-radius: 4px;
    background: currentColor;
    position: absolute;
    top: 47%;
    transform: translateY(-50%);
    left: 16px;
    box-sizing: border-box;
    animation: animloader 0.3s 0.45s linear infinite alternate;
}

.loader::before {
    left: 4px;
    animation-delay: 0s;
}

@keyframes animloader {
    0% {
        height: 8px
    }

    100% {
        height: 4px
    }
}

// .playing {
//     background: transparent;
//     width: 1rem;
//     height: 2rem;
//     border-radius: .5rem;
//     display: flex;
//     justify-content: space-between;
//     align-items: flex-end;
//     box-sizing: border-box;
//     margin-left: 1rem;
//     margin-top: -2.5rem;
// }

// .playing__bar {
//     display: inline-block;
//     background: white;
//     width: 100%;
//     height: 80%;
//     animation: up-and-down 1.3s ease infinite alternate;
//     margin-right: 2px;
// }

// .playing__bar1 {
//     height: 10%;
//     margin-bottom: -8px;
// }

// .playing__bar2 {
//     height: 20%;
//     animation-delay: -2.2s;
//     margin-bottom: -5px;
// }

// .playing__bar3 {
//     height: 80%;
//     animation-delay: -3.7s;
//     margin-bottom: -10px;
// }

// .playing__bar4 {
//     height: 10%;
//     animation-delay: -4.7s;
//     margin-bottom: -5px;
// }

// .playing__bar5 {
//     height: 20%;
//     animation-delay: -5.7s;
//     margin-bottom: -8px;
// }

// .playing__bar6 {
//     height: 30%;
//     animation-delay: -6.7s;
//     margin-bottom: -10px;
// }

// @keyframes up-and-down {
//     10% {
//         height: 30%;
//         transform: scale(.8);
//         transform: 250ms;
//         transition-timing-function: linear;
//     }

//     30% {
//         height: 60%;
//         transform: scale(1);
//         transform: 250ms;
//         transition-timing-function: linear;
//     }

//     60% {
//         height: 50%;
//         transform: scale(.8);
//         transform: 250ms;
//         transition-timing-function: linear;
//     }

//     80% {
//         height: 75%;
//         transform: scale(1);
//         transform: 250ms;
//         transition-timing-function: linear;
//     }

//     100% {
//         height: 60%;
//         transform: scale(.8);
//         transform: 250ms;
//         transition-timing-function: linear;
//     }
// }

// Meeting room styles ends here

// calender styles start here

.inputHide {
    input {
        display: none;
    }
}

.PlusI svg {
    font-size: 20px;
    background: rgba(12, 12, 12, 0.3019607843);
    border-radius: 50%;
    width: auto;
    border: 1px solid gray;
    margin-right: 2px;
    padding: 1px;
    box-shadow: -1px 0px 4px 0px rgba(255, 255, 255, 0.2509803922) inset;
}

.event-selection {
    background: #2F3151;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
}

.purple {
    color: #A8AEED;
}

.tab-pane {
    height: 75vh;
    overflow: auto;
}

.PMoreM {
    margin-left: 2rem !important;
    text-align: center;
}

.tabs-form {
    padding: 10px 18px;


    .nav-tabs {
        border-bottom: 1px solid;
    }

    .nav-link {
        background: transparent !important;
        color: #C2C2C2;
        border: none !important;
        margin-bottom: 0;
        padding: 5px 0;
    }

    .nav-link.active {
        color: #fff !important;
        border-bottom: 3px solid #fff !important;
    }

    .tab-content {
        display: block;
        background: transparent;
        position: relative;
        border: none;
        overflow: inherit;

        img {
            width: 100%;
        }
    }
}

.calender {
    .hover-cross {
        cursor: pointer;
    }

    .hover-cross:hover .Mclose svg {
        display: inline-block;
    }

    .Mclose svg {
        color: red;
        font-size: 18px;
        vertical-align: middle;
        display: none;
    }

    .fc-theme-standard .fc-popover {
        background: #1c1d36;
        overflow: visible;
        position: sticky;
        width: max-content;
    }

    // .fc-timegrid-event-harness > .fc-timegrid-event{
    //     width: 90%;
    // }
    .grH .gradientBox {
        height: auto;
    }

    th {
        border-bottom: none !important;
        border-color: #ffffff40 !important;
    }

    td {
        font-size: 14px;
    }

    td.fc-timegrid-divider.fc-cell-shaded {
        border: none !important;
        padding: 0;
        height: 0 !important;
        display: none;
    }

    // .fc-direction-ltr .fc-timegrid-col-events {
    //     margin: 0 2px;
    // }
    .fc-timegrid-event-harness>.fc-timegrid-event {
        width: 90%;
    }

    .fc .fc-scrollgrid-section {
        height: 0;
        border: none !important;
    }

    .fc .fc-toolbar.fc-header-toolbar {
        margin-bottom: 1em;
        margin-top: 0.5rem;
        padding: 0 1rem;
    }

    .fc-theme-standard {

        td,
        .fc-theme-standard th {
            border: 1px solid #ffffff40;
            border-left: 0;
            border-bottom: 0;
        }
    }

    .fc-toolbar-title {
        color: #fff;
        font-size: 16px !important;
    }

    .fc-toolbar-chunk:nth-child(3) {
        text-align: right;
    }

    .fc-scrollgrid {
        border: none !important;
        border-top: 1px solid #ffffff40 !important;
    }

    .fc-next-button {
        padding: 3px !important;
    }

    .fc-prev-button {
        padding: 3px !important;
    }

    .fc-button {
        background: #0000 !important;
        border: 1px solid hsla(0, 0%, 100%, .478) !important;
        // border-bottom-color: #6a6a6a !important;
        margin-left: .3rem !important;
        border-radius: 10px !important;
        padding: 3px 15px;
    }

    .fc-view-harness-active {
        height: 65vh !important;
        overflow: auto;
        border-bottom-right-radius: 20px;
    }

    .fc-scroller {
        overflow: hidden auto !important;
    }

    .fc-toolbar-chunk {
        width: 100%;

        h2 {
            text-align: center;
        }
    }

    .fc .fc-daygrid-body-natural {
        width: 100% !important;
    }

    // .fc-day-today .fc-daygrid-day-number {
    //     border-radius: 50%;
    //     background: #2f3151;
    //     margin-right: 2px;
    //     padding: 2px 7px;
    //     margin-top: 2px;
    // }
    .fc .fc-daygrid-day-number {
        margin-right: 5px;
    }

    .fc .fc-daygrid-day.fc-day-today {
        background: #2F3151 !important;
    }

    .fc-button:hover {
        background: #2f3151 !important;
    }

    .fc .fc-button-primary:not(:disabled):active,
    .fc .fc-button-primary:not(:disabled).fc-button-active {
        background-color: #2f3151 !important;
    }

    .fc-button-active {
        background-color: #2f3151 !important;
    }

}

.delete-hide {
    height: 85vh !important;
}

.DS-hide {
    height: 93vh !important;
}

.h-75vh {
    height: 78vh !important;
}

.create-event {
    height: 78vh;

    option {
        color: #fff;
        background: #1c1d36;
    }

    .btn-maroon {
        background: #784444;
        box-shadow: -1px 0px 4px 0px rgba(255, 255, 255, 0.2509803922) inset;
        border-radius: 8px;
        position: fixed;
        bottom: 48px;
        padding: 5px 16px;
        color: white;
        width: 24%;
        text-align: center;
        border: 1px solid transparent;
        margin-bottom: .5rem;

        &:hover {
            background: transparent;
        }
    }


    .form-control {
        font-size: 12px;
    }

    input.rbt-input-main {
        padding: 8px;
        margin-bottom: 5px;
    }

    .rbt div {
        height: auto !important;
    }

    .startdate {
        .react-datepicker-popper {
            transform: translate(-6px, -247px) !important
        }
    }

    .enddate {
        .react-datepicker-popper {
            transform: translate(-76px, -216px) !important;
        }
    }

    .react-datepicker-wrapper {
        input.form-control {
            margin-bottom: 0;
            padding: 8px 6px !important;
        }
    }

    .rbt-menu a {
        margin-top: 0 !important;
        font-size: 12px;
    }
}

.participents {
    border: 1px solid rgba(255, 255, 255, 0.2509803922);
    box-shadow: -1px 0px 4px 0px rgba(255, 255, 255, 0.2509803922) inset;
    background: rgba(33, 34, 52, 0.4);
    padding: 1px 5px;
    margin-bottom: 0.5rem;
    border-radius: 8px;
    display: inline-block;

    svg {
        cursor: pointer;
    }
}

// calender styles ends here

// MyCalender styles start here
.Cdelete {
    svg {
        font-size: 18px;
        border-radius: 50%;
        background: #782824;
        width: auto;
        border: 1px solid gray;
        /* margin-right: 0; */
        padding: 3px;
        color: #fff;
        box-shadow: -1px 0px 4px 0px rgba(255, 255, 255, 0.2509803922) inset;
        backdrop-filter: blur(35.9099998474px);
        position: absolute;
        right: 20px;
        margin-top: -2px;
        cursor: pointer;
    }
}

.edit {
    svg {
        @include icon
    }
}

.collaboration-hub {
    .ch-expand {
        .CIcon {
            svg {
                margin-top: 3.5rem;
            }
        }

        .call {
            svg {
                margin-left: -60px;
            }
        }

        .msg {
            svg {
                margin-left: -27px;
            }
        }
    }

    // .col-3{
    //     p{
    //         margin-top: 1rem;
    //         font-size: 12px;
    //     }
    // }
    .All {
        font-size: 14px;
        background: rgba(12, 12, 12, 0.3019607843);
        border-radius: 50%;
        border: 1px solid gray;
        padding: 5px 5px;
        color: #fff;
        box-shadow: -1px 0px 4px 0px rgba(255, 255, 255, 0.2509803922) inset;
        backdrop-filter: blur(35.9099998474px);
        vertical-align: middle;
        cursor: pointer;
    }

    .Eplus {
        font-size: 28px;
        background: rgba(12, 12, 12, 0.3019607843);
        border-radius: 50%;
        border: 1px solid gray;
        margin-left: 2px;
        padding: 5px;
        box-shadow: -1px 0px 4px 0px rgba(255, 255, 255, 0.2509803922) inset;
        vertical-align: middle;
        cursor: pointer;
    }
}

.CardImg {
    @include cmix;
    object-fit: cover;
    border-radius: 50%;
    background: #303252;
    border: 1px solid rgba(255, 255, 255, 0.2509803922);
    width: 80%;
    // margin-right: 5px;
    // margin-bottom: 1rem;
    margin: auto;
}

.call {
    svg {
        margin-left: -48px;
    }
}

.msg {
    svg {
        margin-left: -24px;
    }
}

.CIcon {
    margin-bottom: 1rem;

    svg {
        font-size: 20px;
        background: rgba(12, 12, 12, 0.3019607843);
        border-radius: 50%;
        width: auto;
        border: 1px solid gray;
        margin-right: 0;
        padding: 3px;
        color: #fff;
        box-shadow: -1px 0px 4px 0px rgba(255, 255, 255, 0.2509803922) inset;
        backdrop-filter: blur(35.9099998474px);
        margin-top: 3rem;
        position: absolute;
        cursor: pointer;
    }
}

// MyCalender styles ends here

// Checkins styles start here
.Cattached-img{
    width: 35px;
    height: 35px;
    border-radius: 8px;
    margin: 2px;
}
.Cattached-img-full {
    width: 120px;
    height: 120px;
    margin-right: 0.5rem;
    object-fit: cover;
    border-radius: 10px;
}
.Checkin-overflow{
    max-height: 74vh;
    overflow: auto;
}
.CheckinSection{
    max-height: 83vh;
}
// modal styles start here
.modal-dialog {
    min-height: 90vh;
    align-items: center;
    justify-content: center;
    display: flex;
}

.modal {
    z-index: 40000;
}

.modal-content {
    background: #0C0C0C4D;
    border-radius: 20px;
    border: 1px solid gray;
    box-shadow: -1px 0px 4px 0px #FFFFFF40 inset;
    backdrop-filter: blur(35.90999984741211px);
}

// modal styles ends here
// range slider styles stsrt here
.my-profile {
    .slidecontainer {
        width: 100%;
    }

    .slider {
        -webkit-appearance: none;
        width: 100%;
        height: 20px;
        background: #04F1B2;
        outline: none;
        -webkit-transition: .2s;
        transition: opacity .2s;
        position: relative;
        border-radius: 8px;
    }

    .slider:hover {
        opacity: 1;
    }

    .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 50%;
        height: 20px;
        background: #000000;
        cursor: pointer;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    .peach {
        .slider {
            background: #6468A8;
        }

        .slider::-webkit-slider-thumb {
            width: 65%;
        }
    }

    .blue {
        .slider {
            background: #38B8F3;
        }

        .slider::-webkit-slider-thumb {
            width: 75%;
        }
    }

    .slider::-moz-range-thumb {
        width: 25px;
        height: 25px;
        background: #d3d3d3;
        cursor: pointer;
    }

    .slider:before {
        display: none;
    }
}


// Wellnesscenter styles start here
.wellness-emji{
    em-emoji-picker {
        position: fixed;
        bottom: 44px;
        left: 0;
    }
    
}

.Stab {
    font-size: 14px;
    background: rgba(12, 12, 12, 0.3019607843);
    backdrop-filter: blur(35.9099998474px);
    box-shadow: 2px 1px 4px 0px rgba(255, 255, 255, 0.2509803922) inset;
    padding: 8px 5px;
    border: 1px solid gray;
    border-radius: 11px;
    margin-bottom: .5rem;
    cursor: pointer;

    img {
        @include cmix;
        width: 32px;
        height: 33px;
        margin: .2rem .5rem;
    }
    input:checked + .slider {
        background: #038500;
    }
    .slider{
        border-radius: 12px;
        background: rgba(12, 12, 12, 0.3019607843);
        backdrop-filter: blur(35.9099998474px);
        box-shadow: 2px 1px 4px 0px rgba(255, 255, 255, 0.2509803922) inset;
        border: 1px solid gray;
        width: 66%;
        margin-left: auto;
    }
    .slider:before {
        position: absolute;
        content: "";
        height: 15px;
        width: 15px;
        left: 5px;
        bottom: 4px;
        background-color: white;
        transition: 0.4s;
        top: 2px;
        border-radius: 50%;
    }
}

.PR-P {
    padding: 10px 5px;
}

.Pimg {
    width: 15px;
}

.chat-emoji {
    border: 1px solid gray;
    margin-left: 2px;
    border-radius: 50%;
    margin-right: 1px;
    vertical-align: middle;
}

.Wsearch {
    background: transparent;
    border: none;
    padding: 10px 5px;
    color: #fff;
    outline: 0;
}

.Wsend {
    font-size: 30px !important;
    padding: 5px !important;
    color: #fff;
    margin-top: 6px;
}

.Mmsg {
    background: #0C0C0C4D;
    backdrop-filter: blur(35.90999984741211px);
    box-shadow: -1px 0px 4px 0px #FFFFFF40 inset;
    padding: 10px;
    font-size: 12px;
    border-radius: 10px;
    max-width: 40%;
    min-width: auto;
    border: 1px solid gray;
}

.Lbox {
    background: #0C0C0C4D;
    padding: 5px !important;
    border: 1px solid gray;
    border-radius: 10px;
    backdrop-filter: blur(35.90999984741211px);
    box-shadow: -1px 0px 4px 0px #FFFFFF40 inset;
    margin-bottom: .5rem !important;
    margin: 10px;
}

.Timg {
    width: 33px;
    height: 37px;
    border: 1px solid gray;
    border-radius: 5px;
    margin-left: 5px;
    object-fit: cover;
}

.meditation {
    .gradientBox {
        height: auto;
    }
}

.Moverflow {
    max-height: 30vh;
    overflow: auto;
}

    .selectedblue {
        background: #2F3151;
    }
    .br-7 {
        border-radius: 7px;
    }



//   range slider styles ends here


// Files styles starts here
.AS-body {
    background: rgb(49 48 48 / 94%);
    border-radius: 20px;
    border: 1px solid gray;
    position: absolute;
    right: 0;
    z-index: 3;
    padding: 10px;
    backdrop-filter: blur(35.90999984741211px);
    box-shadow: -1px 0px 4px 0px #FFFFFF40 inset;
    width: 70%;
}
.advanced-search svg {
    font-size: 26px !important;
    right: 0;
    margin: 0 !important;
    margin-top: 2px !important;
    cursor: pointer;
}
.fontsize-65{
    font-size: 65px;
}
.folder-box {
    border: 1px solid rgba(255, 255, 255, 0.2509803922);
    box-shadow: -1px 0px 4px 0px rgba(255, 255, 255, 0.2509803922) inset;
    width: max-content;
    margin: auto;
    padding: 10px;
    border-radius: 10px;
    padding-top: 0;
}
.Cred{
    color: red;
}
.EmpDropdown {
    position: absolute;
    background: #2f3151;
    z-index: 2;
    width: 92%;
    padding: 10px;
    padding-bottom: 0;
    margin-top: -0.5rem;
    border: 1px solid gray;
    border-radius: 8px;
    max-height: 210px;
    overflow: auto;
}
.files {
    .popup-box {
        position: absolute;
        right: 10px;
        background: #2b2b2c;
        border-radius: 20px;
        padding: 12px;
        border: 1px solid gray;
        margin-top: 2rem;
        box-shadow: -1px 0px 4px 0px rgba(255, 255, 255, 0.2509803922) inset;
    }
    .file-menu {
        padding: 5px 6px;
    }

    .file-menu.select {
        background: #2F3151;
        box-shadow: -1px 0px 4px 0px rgba(255, 255, 255, 0.2509803922) inset;
        border-radius: 20px;
        border: 1px solid rgba(255, 255, 255, 0.4196078431);
    }

    .col-3 .gradientBox {
        height: 100%;
    }

    .b-hide.gradientBox {
        height: auto !important;
    }

    tr {
        border-bottom: 1px solid gray;
    }

    th,
    td {
        color: #fff;
        padding: 12px 8px;
        font-size: 12px;
    }

    td {
        svg {
            font-size: 16px;
            margin-right: 5px;
            vertical-align: text-bottom;
        }
    }
}
.labelAdd {
    position: absolute;
    right: 1.4rem;
    margin-top: -2.6rem;
}
.FGradient {
    .gradientBox {
        .body {
            padding: 0 0 15px 0 !important;

            .cbody {
                height: 65vh;
                overflow: auto;
            }
        }

    }
}
.Theight {
    height: 55vh;
    overflow: auto;
}
.Fplus{
    svg{
        padding: 5px !important;
        font-size: 35px !important;
    }
}
// Files styles ends here

/* responsive styles start here */
@media (max-width: 1000px) {
    .mb-none {
        display: none;
    }

    .content-body-mr {
        padding: 0;
    }

    .NavTab .tab-content {
        display: contents;
    }

    .right-sidemenu {
        width: 95%;
    }
}

@media (max-width: 1050px) {
    .conference {
        .personImg img {
            width: 120px !important;
            height: 120px !important;
            border-radius: 50%;
            margin: 0 !important;
        }

        .right-sidemenu {
            .col-9 {
                flex: 0 0 75% !important;
                max-width: 75% !important;
            }

            .col-3 {
                flex: 0 0 25% !important;
                max-width: 25% !important;
            }

            .col-10 {
                flex: 0 0 83.333333% !important;
                max-width: 83.333333% !important;
            }
        }
    }

    .PMcol {
        .col-9 {
            flex: 0 0 75% !important;
            max-width: 75% !important;
        }

        .col-3 {
            flex: 0 0 25% !important;
            max-width: 25% !important;
        }
    }

    .ConferenceMain.w-40 {
        width: 100% !important;
        height: auto;
    }

    .wifi {
        margin-top: 0 !important;
    }

    .col-5 {
        flex: 100%;
        max-width: 100%;
    }

    .rooms {
        .col-5 {
            padding: 0;
        }
    }

    .modal-dialog {
        width: 95%;
    }

    .checkin-btn {
        width: 95% !important;
    }

    .btn-maroon {
        width: 95% !important;
        left: 5%;
    }

    .Grheader {
        svg {
            padding: 3px !important;
            font-size: 23px !important;
            margin-right: 0.5rem !important;
        }
    }

    .dp {
        display: block;

        .backArrow {
            svg {
                margin-bottom: 5px;
            }
        }

        .gradientBox {
            height: auto !important;
        }
    }

    .calender {
        .tab-content {
            min-height: auto !important;

            .Grheader {
                .react-datepicker-popper {
                    transform: translate(79px, 51px) !important;
                }
            }

            .col-1 {
                max-width: 100%;
                flex: auto;
            }

            .col-11 {
                max-width: 100%;
                flex: auto;
                padding: 10px !important;
            }

            .col-2 {
                max-width: 100%;
                flex: auto;
            }

            .event-selection {
                display: block;
                margin-top: 1rem !important;
                border-radius: 20px;
            }
        }

        .pl-2 {
            padding-left: 0 !important;
        }

    }

    .mb-p0 {
        padding: 0
    }

    .main {
        @include bg;
        max-height: fit-content;
    }

    .col-8,
    .col-4,
    .col-6 {
        max-width: 100% !important;
        flex: 100% !important;
    }

    .inner {
        width: 100%;
    }

    .mb-wd {
        flex: 0 0 83.333333% !important;
        max-width: 83.333333% !important;
    }

    .content {
        width: 95%;
        display: block;
        margin: auto;
        padding-top: 1rem;
    }

    .welcome-text {
        margin: auto;
    }

    .signin-form {
        // margin-left: 0;
        margin-top: 1rem;
        height: auto;
        margin-bottom: 1rem;
    }

    .sidenav {
        width: 100% !important;
    }

    .gradientBox {
        margin-left: 0 !important;
        margin-bottom: 1rem !important;
    }

    .profile-info {
        width: 100% !important;
        margin-top: .5rem !important;
    }

    .body-content {
        display: block !important;
        margin-left: 1rem;
        width: 90%;
    }

    .details-box {
        width: 100% !important;
        margin-left: 0 !important;
        margin-top: .5rem !important;
    }

    .sidenav {
        padding-bottom: 0 !important;
    }

    .content-box-body p {
        margin: 5px !important;
    }

    .content-box-body {
        margin-top: 0 !important;
    }

    .menu-section {
        margin-top: .5rem !important;
    }

    .headerdropdown {
        margin-left: 5px !important;
    }

    .navbar-toggler-icon {
        filter: invert(1);
    }

    .conference {
        .Mh-auto {
            height: auto !important;

            .Guestimg {
                height: auto !important;

                img {
                    margin-top: 1rem;
                }
            }
        }

        .agora-rtc-video {
            height: 100% !important;
        }

        .col-5 {
            flex: 100%;
            max-width: 100%;
        }

        .w-65 {
            width: 100% !important;
            height: auto;
        }

        .border-left {
            border: none !important;
        }

        .cfooter {
            text-align: center;

            button {
                margin-left: 0 !important;
            }
        }
    }

    .ConferenceMain {
        height: 60vh;

        .personMain {
            border-bottom: 1px solid #ffffff40 !important;
            border-left: none !important;
            border-top: none !important;
            flex: 50% 1 !important;
        }

        .personImg {
            margin-top: -2.3rem !important;

            img {
                width: 120px !important;
                height: 120px !important;
                margin: 0 !important;
            }
        }
    }

    .Cbtn .float-right {
        float: inherit !important;
        display: inline-block;
        margin-right: 4px;
    }

    .Cbtn {
        margin-top: 1rem;

        svg {
            margin-right: 4px !important;
            margin-top: 0 !important;
        }
    }

    // svg{    
    //     padding: 6px !important;
    //     font-size: 34px !important;
    //     margin-right: .5rem !important;

    // }
    .btn-exit {
        svg {
            font-size: 33px ! IMPORTANT;
            box-shadow: none;
            padding: 0 !important;
        }
    }
}

.static-backdrop {
    opacity: 0.6;
    pointer-events: none
}

:root {
    --fc-today-bg-color: transparent !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    background-image: none !important;
}