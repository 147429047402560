.agora-rtc-video {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  /*  overflow: hidden;
     display:flex; */
}
.agora-rtc-video div {
  text-align: left;
}
span.audio-sig {
  position: absolute;
  color: #fff;
  bottom: 20px;
  right: 20px;
  z-index: 2;
}
button.CollabLeave {
  border: none;
  background-color: transparent;
}

.CollabCard {
  background: #000;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
span.participantName {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 11;
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  width: 100%;
  color: #fff;
  font-size: 12px;
  font-weight: 300;
  text-align: left;
  padding: 5px 15px;
}

.CollabMiniCaller .agora-rtc-video img,
.GroupCallCards .agora-rtc-video img {
  width: 100px;
  margin: 10px auto;
}

.CollabCard .agora-rtc-video img {
  width: 190px;
  margin: 15% auto;
}

.pinUnpinMe {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  display: block;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  z-index: 10;
  background: #000;
}
.pinUnpinMe:hover,
.pinUnpinMe.active {
  background: #2e3151;
}
.pinUnpinMe svg {
  width: 14px;
  height: 14px;
  transform: rotate(45deg);
}

.network {
  position: absolute;
  top: 5px;
  left: 5px;
  display: block;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  z-index: 10;
  background: #fff;
}
.network img {
  width: 24px !important;
  height: 24px !important;
  margin: 0 !important;
  left:2px !important;
}

.GroupCallCards {
  position: absolute;
  top: 5px;
  left: 5px;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
}
.GroupCallCards.pps {
  display: grid;
  grid-gap: 1%;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  overflow: auto;
}
/*    :nth-child(n+X)      all children from the Xth position onward */
/*    :nth-child(-n+x)     all children up to the Xth position       */

/* .collabExpanded .GroupCallCards.pp4 .agora-rtc-video {
  height: 44vh !important;
}
.collabExpanded .GroupCallCards.pp5 .agora-rtc-video {
  height: 44vh !important;
} */
/* .GroupCallCards.pp5 .agora-rtc-video,
.GroupCallCards.pp2 .agora-rtc-video {
  height: auto !important;
}
.GroupCallCards.pp4 .agora-rtc-video {
  height: auto !important;
} */

.GroupCallCards.pp1,
.GroupCallCards.pp2,
.GroupCallCards.pp4 {
  grid-template-columns: repeat(2, 1fr);
}
.GroupCallCards.pp5,
.GroupCallCards.pp3 {
  grid-template-columns: repeat(3, 1fr);
}

span.notallowed {
  position: absolute;
  display: block;
  margin-left: 22px;
  margin-top: -4px;
  background: #f1a81a;
  font-size: 13px;
  font-weight: bold;
  width: 43%;
  height: 43%;
  border-radius: 50%;
}
span.warning {
  position: absolute;
  display: block;
  margin-left: 18px;
  margin-top: -4px;
  background: #f1a81a;
  font-size: 11px;
  text-align: center;
  font-weight: bold;
  width: 47%;
  height: 47%;
  border-radius: 50%;
  color: rgb(209, 58, 91);
}
.test-video {
  width: 100%;
  height: 100%;
}
.cam-card {
  border: 1px solid gray;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 0.3125rem;
  text-align: center;
  width: 100%;
}
.CollatMenu {
  width: 100% !important;
  margin-left: 0 !important;
  height: 100% !important;
  margin-top: revert !important;
  z-index: 1000 !important;
}

.CollatMenu li {
  display: inline-block;
  cursor: pointer;
  position: relative;
}

.CollatMenu li:hover {
  filter: brightness(0.9);
}

.CollatMenu li img {
  width: 40px;
  height: 40px;
}
.CollatMenu li.active .inactived,
.CollatMenu li .actived {
  display: none;
}

.CollatMenu li .inactived,
.CollatMenu li.active .actived {
  display: inline-block;
}
@media screen and (max-width: 900px) and (min-width: 550px) {
  .emji {
    right: 70px !important;
  }
}
span.blur-icon {
  background: #6264a5;
  padding: 2px 10px;
  display: inline-block;
  font-size: 25px;
  border-radius: 8px;
  margin: 2px;
  cursor: pointer;
  color: #fff;
}
.fixed-color {
  width: 25px;
  height: 25px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;
}
img.video-bg {
  width: 20%;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 8px;
  height: 50px;
  object-fit: cover;
  cursor: pointer;
}
.device-name {
  color: white;
}
.starSvg {
  font-size: 36px;
  border: 1px solid #fff;
  padding: 5px;
  border-radius: 50%;
  margin-right: 1rem;
}
.bgActive {
  border: 2px solid #30D5C8;
  box-shadow: 0px 0px 2px 5px #8a968a;
  zoom: 1.05;
}
.audio svg {
  width: auto !important;
  height: auto !important;
  transform: inherit !important;
}
.first {
  fill: none !important;
}

.audio {
  width: 29px;
  height: 29px;
  left: 0;
  background: #fff;
  cursor: none !important;
}

.mute-icon-color {
  background: #ff0000;
}
.pinUnpinMe svg {
  width: 14px;
  height: 14px;
  transform: rotate(45deg);
}

.blur-icon svg  {
  color: #fff;
}
.vis {
  visibility: hidden;
  z-index: 0;
  position: absolute;
  top: -300px;
}
.heading {
  position: relative;
  font-size: 18px;
  z-index: 1;
  overflow: hidden;
  text-align: center;
  color: #fff;
  margin-top: -1.5rem;
}
.heading:before, .heading:after {
  position: absolute;
  top: 51%;
  overflow: hidden;
  width: 5%;
  height: 1px;
  content: '\a0';
  background-color: #fff;
}
.heading:before {
  margin-left: -5%;
  text-align: right;
}
/* .line {
  margin-top: -0.7rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #ffffff;
  z-index: 2;
  position: absolute;
  width: 17%;
  left: 43%;
}
.heading {
  color: #fff;
  text-align: center;
  position: absolute;
  left: 46%;
  z-index: 2;
  background: black;
  top: -1.4rem;
  padding: 0 5px;
} */
.SideNav .videoCallBox {
  margin: auto;
}
.SideNav .videoCallBox .agora-rtc-video img {
  left: 0;
}
.standed-bg.popover-body {
  background: #2e3151 !important;
}
.time{
  color: #fff;
  left: 5rem;
}