.VAssistent {
    position: fixed;
    bottom: 0px;
    right: 11px;
    color: #fff;
    text-decoration: none;
    border-radius: 50%;    
    z-index: 3000;
    text-align: center;
    img{
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 50%;
        cursor: pointer;
    }
    h6{
        font-size: 14px;
        background: #1D1F3C;
        padding: 6px 10px;
        border-radius: 12px;
        box-shadow: -1px 0px 4px 0px #FFFFFF40 inset;
        border-bottom-right-radius: 0;
        border: 1px solid #ffffff40;
        margin-top: -5px;
        z-index: 2;
        position: relative;
        cursor: pointer;
    }
}