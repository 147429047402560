.Collaborate_PODBG {
    background-image: url("/public/assets/media/images/Collaborate_POD.jpg");
  }
  .Collaborate_PODBG .ParticipantDesignation img {
    width: 22px;
    display: inline-block;
  }
  .Collaborate_PODBG .ParticipantName {
    margin-top: 0px;
  }
  .Collaborate_PODBG .addTeamMemberBox .dropdown-menu,
  .Collaborate_PODBG .addTeamMemberBox .dropdown-menu .dropdown-item {
    float: left;
    width: 100%;
    padding: 0;
  }
  .Collaborate_PODBG .addTeamMemberBox .dropdown-menu,
  .Collaborate_PODBG .addTeamMemberBox .dropdown-menu .dropdown-item {
    background-color: #2e3151;
    box-shadow: 1px 1px 9px 0px #1b1d31;
  }
  