/* navbar styles start here */
.navbar {
    background: #2F3151 !important;
    padding: 5px 10px;
}
.navlink {
    color: #fff !important;
    font-size: 16px;
}

.headerdropdown {
    color: #fff;
    // margin-left: 12px;
    border: 1px solid #fff;
    border-radius: 10px;
    background: rgba(33, 34, 52, 0.4) !important;
    font-size: 14px;
    box-shadow: none !important;
    border-color: #fff !important;
}
.headerdropdown:hover, .headerdropdown:focus{
    color: #fff;
    border: 1px solid #fff;
}
.bubblegreen {
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 0.5rem;
    background: #48ff45cc;
}
.bubbleorange {
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 0.5rem;
    background: #FF9500;
}
.bubblered {
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 0.5rem;
    background: #FF3B30;
}

.notificationbubble{
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 50%;
    background: #42d445;
    position: absolute;
    margin-top: 5px;
    margin-left: -12px;
}
span.navbar-toggler-icon {
    filter: invert(1);
}
.rightnav{
    display: flex;
    float:right;
    margin-left: auto
}
.w71{
    width: 71%;
}
.notification{
     font-size: 35px;
    width: 30px;
    color: #fff;
    height: 35px;
    margin-left: 5px;
}
.w90p{
    width: 90px;
}

@media (max-width: 1050px) {
    .headerdropdown{
        margin-left: 5px !important;
    }
    .rightnav{
        float: none !important;
    }
}
/* navbar styles ends here */
