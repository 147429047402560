#clock {
  position: fixed;
  z-index: 300000;
  width: 100%;
  background: #000000db;
  height: 100%;
  left: 0;
  top: 0;
  }
  #countdownclock {
      position: absolute;
      margin: auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
}
#clock .clockline {
  fill: #6264A5;
  stroke: #30D5C8;
  stroke-width: 5;
  stroke-linecap: round;
  stroke-miterlimit: 1;
  stroke-dasharray: 75 150;
  stroke-dashoffset: 1100;
  animation: draw 8s linear infinite;
  fill-opacity: 1;
  /* animation-direction: reverse; */
}
  
  @keyframes draw {
    20% {
        stroke: #30D5C8
    }
    40% {
        stroke: #30D5C8
    }
    60% {
        stroke: #30D5C8
    }
    100% {
        stroke-dashoffset: 0;
        stroke: #30D5C8;
    }
  }
  