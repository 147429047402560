.menuactive{
    background: #2F3151;
    // padding: 3px 4px;
    box-shadow: -1px 0px 4px 0px #FFFFFF40 inset;
    border-radius: 20px;
    border: 1px solid #ffffff6b;
    svg{
        background: #2F3151;
    padding: 4px;
    box-shadow: -1px 0px 4px 0px #ffffff40 inset;
    border-radius: 20px;
    font-size: 25px;
    width: 32px !important;
    height: 32px;
    border: 1px solid #ffffff6b;
    margin-right: 5px;
    }
    img{
        background: #2F3151;
        padding: 4px;
        box-shadow: -1px 0px 4px 0px #ffffff40 inset;
        border-radius: 20px;
        font-size: 25px;
        width: 32px !important;
        height: 32px;
        border: 1px solid #ffffff6b;
        margin-right: 5px;
    }
}
a{
    text-decoration: none !important;
}